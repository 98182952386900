<template>
  <div class="margins pt-2">
    <div
      v-for="(asset, index) in assets"
      :key="asset.skuCode"
      :class="index !== 0 && index !== assets.length - 1 ? 'mb-4' : ''"
    >
      <div class="text-strong h6 border-bottom pb-2">
        <span> {{ asset.name }} </span>
        <span v-if="index !== 0">
          {{ $t('YOUR_PLAN_TABLE.CATEGORY_1', [index + 1]) }}
        </span>
      </div>

      <div
        v-for="(service, index) in dynamicServices"
        :key="service.name"
        class="text-small d-flex align-items-center border-bottom py-1"
      >
        <div class="icon table__icon">
          <componet :is="dynamicIcons[index]" />
        </div>
        <div>
          <span class="text-uppercase mr-2">{{ service.name }}</span>
          <span>{{ service.description }}</span>
        </div>
      </div>
    </div>
    <template v-for="item in footerItems">
      <div
        v-if="item.isVisible"
        :key="item.name"
        class="summary d-flex justify-content-between border-bottom text-small"
      >
        <span :class="[item.name === 'monthlyAmount' ? 'text-strong' : '']">
          {{ $t(item.title) }}
        </span>
        <span
          :class="[
            item.name === 'monthlyAmount' ? 'text-strong h6' : 'text-grey',
          ]"
        >
          {{ item.value }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StihlInstallationSVG from '@/assets/images/stihl-installation.svg';
import StihlServiceSVG from '@/assets/images/stihl-service.svg';
import StihlWinterStorageSVG from '@/assets/images/stihl-winter-storage.svg';
import StihlPerfectPlanSVG from '@/assets/images/stihl-perfect-plan.svg';
import StihlGuaranteeSVG from '@/assets/images/product-registration-icon.svg';
import { constants } from '@/mixins';
import { formatPrice } from '@/utils/BundleUtil';

export default {
  name: 'YourPlanTable',
  mixins: [constants],
  props: {
    showPeriod: Boolean,
    showPaymentAmount: Boolean,
  },
  computed: {
    ...mapState(['selectedBundle']),
    assets() {
      return this.selectedBundle?.assets;
    },
    bundle() {
      return this.selectedBundle || {};
    },
    monthlyPaymentAmount() {
      return formatPrice(this.selectedBundle.paymentAmount);
    },
    isDynamicServicesAvailable() {
      return this.bundle?.additionalData?.dynamicServices;
    },
    dynamicServices() {
      if (this.isDynamicServicesAvailable) {
        return this.bundle?.services?.map(({ name, description }) => ({
          name: name,
          description: description,
        }));
      }
      return this.SERVICE_TYPES.map((service) => ({
        name: '',
        description: this.$t(`SERVICES.${service.type}`),
      }));
    },
    dynamicIcons() {
      return [
        StihlInstallationSVG,
        StihlServiceSVG,
        StihlWinterStorageSVG,
        StihlGuaranteeSVG,
        StihlPerfectPlanSVG,
      ];
    },
    footerItems() {
      return [
        {
          name: 'totalAmount',
          title: 'YOUR_PLAN_TABLE.CATEGORY_2',
          value: `${this.bundle.totalAmount} €`,
          isVisible: true,
        },
        {
          name: 'renewalPeriod',
          title: 'YOUR_PLAN_TABLE.CATEGORY_5',
          value: `${this.bundle.renewalPeriodMonths} ${this.$t('MONTHS')}`,
          isVisible: this.showPeriod,
        },
        {
          name: 'period',
          title: 'YOUR_PLAN_TABLE.CATEGORY_6',
          value: `${this.bundle.periodMonths} ${this.$t('MONTHS')}`,
          isVisible: this.showPeriod,
        },
        {
          name: 'monthlyAmount',
          title: 'YOUR_PLAN_TABLE.CATEGORY_TOTAL',
          value: `${this.monthlyPaymentAmount} €`,
          isVisible: this.showPaymentAmount,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.table__icon {
  display: block;
  color: $color-white;
  width: 30px;
  height: 30px;
  margin-right: rem(10px);
  @include min-width(sm) {
    left: 2rem;
  }
}
.border-bottom {
  border: solid $color-grey-disabled;
  border-width: 0 0 rem(1px) 0;
}
.summary.border-bottom {
  padding: 0 0 0.625rem 0;

  &:last-child {
    border-bottom: 0;
  }
}
</style>
